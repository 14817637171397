// vendors
import 'lazyload/lazyload.min';
import './animations';
// polyfills
import 'svgxuse';
import 'nodelist-foreach-polyfill';

// components
import './ieDetect';
import './supports';
import './custom-modal';
import './mobile-menu';
import './fields';


$(document).ready(function () {
  $('img[data-src]').lazyload({
    effect: "fadeIn"
  });

  // check sidebar
  if ($('.page').length && !$('.page').find('.page__sidebar').length) {
    $('.page').addClass('page--full');
  }

  // nav podmenu

  $('.nav ul li').each(function () {

    if ($(this).children('ul').length) {
      $(this).addClass('has-podmenu');

      $(this).mouseover(function () {
        $(this).addClass('hover');
        $(this).children('ul').show();
      }).mouseout(function () {
        $(this).removeClass('hover');
        $(this).children('ul').hide();
      });

    }

  });

  //-

});