// slicer text
export function slicer(text, limit) {
    text = text.trim();
    if (text.length <= limit) return text;
    text = text.slice(0, limit);
    return text.trim() + '...';
}


$('.photo-report .typography p').each(function () {
    $(this).html(slicer($(this).text(), 180));
});
$('.photo-report__title').each(function () {
    $(this).html(slicer($(this).text(), 85));
});
$('.photo-desc').each(function () {
    $(this).html(slicer($(this).text(), 30));
});

$('.news-block__title').each(function () {
    $(this).html(slicer($(this).text(), 48));
});
$('.news-block__desc').each(function () {
    $(this).html(slicer($(this).text(), 154));
});
//-