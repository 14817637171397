 // mobile menu
 let menu_btn = "<div class='menu-button'><span></span><span></span><span></span></div>"
 let mob_menu = '<div class="mobile-menu"><div class="mobile-menu__buttons"><div class="container"></div></div><nav class="mobile-menu__nav container"></nav></div>'
 let header_buttons = $('.header__buttons > *').clone();
 let social = $('.top-social').clone();

 var header_menu = $('.nav .container > ul').clone();

 $('.header__row').append(menu_btn);
 $('body').append(mob_menu);
 $('.mobile-menu__buttons .container').append(header_buttons);
 $('.mobile-menu__nav').append(header_menu, social);

 function menuPosition() {
   let header_height = $('.header').innerHeight();
   $('.mobile-menu').css({
     'top': header_height,
     'height': `calc(100vh - ${header_height}px)`
   });
 }
 $(document).ready(function () {
   setTimeout(() => {
     menuPosition();
   }, 1000)
 });

 $(window).on('resize', function () {
   menuPosition();
 });



 $('.menu-button').click(function () {
   $('body').toggleClass('overflow-bg');
   $(this).toggleClass('open');
   $('.mobile-menu').toggleClass('open');
 });