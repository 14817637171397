  // js modal
  $('body').on('click', '.js-custom-modal', function (e) {
    e.preventDefault();
    let currentWindow = $(this).attr('data-modal-id');
    $('.modal').removeClass('active');
    $('div.modal[id="' + currentWindow + '"]').addClass('active');
    //$('body').addClass('overflow');
  });
  $('body').on('click', '.js-hide-modal', function (e) {
    e.preventDefault();
    $('.modal').removeClass('active');
    //$('body').removeClass('overflow');
  });


  $(document).ready(function () {

    setTimeout(() => {
      $('.modal').addClass('init');
    }, 500);

  });