/* eslint-disable */
import {
  TimelineLite
} from 'gsap';
import {
  posChecker
} from './posChecker';


function startAnimationSections(sections) {
  sections.forEach(function (section, i) {
    if ($(section[0]).length) {
      posChecker($(section[0]), section[1]);
    }
  });
}

// animation
$(window).bind("load", function () {
  var tl_loader = new TimelineLite();
  tl_loader
    .fromTo('.loader__inside', 0.3, {

      opacity: 1
    }, {
      opacity: 0
    })
    .fromTo('.loader', 0.4, {
      x: 0,
      opacity: 1
    }, {
      x: 0,
      opacity: 0,
      onComplete: function () {
        let loader = $('.loader');
        loader.remove();
        if (!document.querySelector('.bvi-body')) {
          startAnimationSections(sections);
        }
      }
    })

  //-

  // var tl_intro = new TimelineLite({
  //   paused: true
  // });
  // tl_intro
  //   .fromTo('.intro__image svg', 1, {
  //     scale: 1.1
  //   }, {
  //     scale: 1
  //   })

  if (!document.querySelector('.bvi-body')) {

    var tl_about = new TimelineLite({
      paused: true
    });
    tl_about
      .staggerFromTo('.about__col', 1, {
        opacity: 0,
        y: 15
      }, {
        y: 0,
        opacity: 1
      }, 0.3)


    var tl_clubs = new TimelineLite({
      paused: true
    });
    tl_clubs
      .staggerFromTo('.sports-clubs__col', 1, {
        opacity: 0,
        y: 15
      }, {
        y: 0,
        opacity: 1
      }, 0.3)

    var tl_posters = new TimelineLite({
      paused: true
    });
    tl_posters
      .fromTo('.posters .module-title', 0.5, {
        opacity: 0
      }, {
        opacity: 1
      })
      .staggerFromTo('.posters .poster-item', 0.5, {
        opacity: 0,
        y: 10
      }, {
        y: 0,
        opacity: 1
      }, 0.25)
      .fromTo('.posters__buttons', 0.5, {
        opacity: 0
      }, {
        opacity: 1
      })


    var tl_objects = new TimelineLite({
      paused: true
    });


    var tl_news = new TimelineLite({
      paused: true
    });
    tl_news
      .fromTo('.news .module-title', 0.5, {
        opacity: 0
      }, {
        opacity: 1
      })
      .staggerFromTo('.news__col', 1, {
        opacity: 0,
        y: 15
      }, {
        y: 0,
        opacity: 1
      }, 0.15).fromTo('.news__buttons', 0.5, {
        opacity: 0
      }, {
        opacity: 1
      })


    var tl_photo = new TimelineLite({
      paused: true
    });
    tl_photo
      .fromTo('.photo-report__content', 0.5, {
        opacity: 0,
        x: -15,
      }, {
        opacity: 1,
        x: 0
      })
      .staggerFromTo('.photo-report__photo', 0.35, {
        opacity: 0
      }, {
        opacity: 1
      }, 0.15)

    var sections = [

      [
        '.about', tl_about
      ],
      [
        '.sports-clubs', tl_clubs
      ],
      [
        '.posters', tl_posters
      ],
      [
        '.news', tl_news
      ],
      [
        '.objects', tl_objects
      ],
      [
        '.photo-report', tl_photo
      ]

    ]

  }
});

/* eslint-enable */